:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color-scheme: normal;
}

body {
  line-height: 1.2 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}
a {
  text-decoration: none;
}
a:hover, a:active {
  text-decoration: underline 1px solid;
}

h1 {
  font-size: 1.8rem;
  font-weight: 500;
  font-family: "Rajdhani", "Roboto", "Helvetica", "Arial", sans-serif;
}
h2 {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "Rajdhani", "Roboto", "Helvetica", "Arial", sans-serif;
}
h3 {
  font-size: 1.4rem;
  font-weight: 500;
  font-family: "Rajdhani", "Roboto", "Helvetica", "Arial", sans-serif;
}
h4 {
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "Rajdhani", "Roboto", "Helvetica", "Arial", sans-serif;
}
h5 {
  font-size: 1.1rem;
  font-weight: 500;
}
h6 {
  font-size: 1rem;
  font-weight: 600;
}
